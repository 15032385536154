import * as React from 'react';
import * as Rebass from 'rebass';
import { Layout } from '../../../components/layout';

export const CompanyPage = () => (
  <Layout>
    <Rebass.Text>Team</Rebass.Text>
  </Layout>
);

export default CompanyPage;
